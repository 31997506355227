import React, { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
// import { Alert, AlertDescription } from "./ui/alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@headlessui/react"; // For the modal
// import { useSocket } from '../contexts/SocketContext'; // WebSocket integration
import {
  fetchLeaderboard,
  getActiveMatches,
  checkInTournament,
  submitMatchScore,
  getPlayerStat,
  updateProfile,
  leaveTournament,
  cancelMatchPlayer,
} from "../services/service"; // Updated API services
import { Input } from "./ui/input";
import { useToast } from "./hooks/use-toast";
import { useSocket } from "../contexts/SocketContext";

const PlayerDashboard = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [leaderboard, setLeaderboard] = useState([]);
  const [currentMatches, setCurrentMatches] = useState([]);
  // const [isCheckedIn, setIsCheckedIn] = useState(false);
  // const [notification, setNotification] = useState('');
  const [player, setPlayer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Control modal state
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false); // Control Update Modal state
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false); // Confirmation modal
  const [selectedMatch, setSelectedMatch] = useState(null); // Selected match for score submission
  const [team1Score, setTeam1Score] = useState("");
  const [team2Score, setTeam2Score] = useState("");
  const [name, setName] = useState("");
  const [skillLevel, setSkillLevel] = useState("beginner");
  const [role, setRole] = useState("investor");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [tournamentStatus, setTournamentStatus] = useState(false);
  // const [total, setTotal] = useState(0);
  const { toast } = useToast();
  const notifyMe = (string) => {
    if (!window.Notification) {
      console.log("Browser does not support notifications.");
    } else {
      // check if permission is already granted
      if (Notification.permission === "granted") {
        // show notification here
        new Notification("Kiva AI Pickleball Club", {
          body: string,
          icon: "https://bit.ly/2DYqRrh",
        });
      } else {
        // request permission from user
        Notification.requestPermission()
          .then(function (p) {
            if (p === "granted") {
              // show notification here
              new Notification("Kiva AI Pickleball Club", {
                body: string,
                icon: "https://bit.ly/2DYqRrh",
              });
            } else {
              console.log("User blocked notifications.");
            }
          })
          .catch(function (err) {
            console.error(err);
          });
      }
    }
  };
  const capitalizeFirstLetter = (str) => {
    if (!str) return ""; // handle empty strings
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role !== "player") {
          navigate("/login");
        }

        const getPlayer = async () => {
          try {
            const { player, status } = await getPlayerStat(
              decodedToken.username
            );
            setPlayer({ ...player });
            localStorage.setItem("id", player._id);
            setName(player.name);
            setSkillLevel(player.skillLevel);
            setRole(player.category);
            setTournamentStatus(status);
            if (player.hasOwnProperty("_id")) {
              const currentMatchesData = await getActiveMatches(player._id);
              setCurrentMatches([...currentMatchesData]);
            }

            // Register the player's socket ID with the server
            if (socket) {
              socket.emit("registerPlayerSocket", { playerId: player._id });
            }
          } catch (err) {
            console.log(err.message);
          }
        };

        getPlayer();
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [socket, navigate]);

  // Fetch leaderboard and current matches
  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const leaderboardData = await fetchLeaderboard(page, perPage);

        setLeaderboard([...leaderboardData.players]);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };
    getDashboardData();
  }, [page, perPage]);

  useEffect(() => {
    // Listen for match notifications
    if (socket) {
      socket.on("matchNotification", async (data) => {
        toast({ title: "Notification", description: data.message });
        notifyMe(data.message);
        const leaderboardData = await fetchLeaderboard(page, perPage);
        setLeaderboard([...leaderboardData.players]);
        const playerId = localStorage.getItem("id");
        if (playerId !== "") {
          const currentMatchesData = await getActiveMatches(playerId);
          setCurrentMatches([...currentMatchesData]);
        }
      });
      socket.on("updateMatch", async () => {
        const playerId = localStorage.getItem("id");
        if (playerId !== "") {
          const currentMatchesData = await getActiveMatches(playerId);
          setCurrentMatches([...currentMatchesData]);
        }
      });

      socket.on("updateStatus", async (status) => {
        setTournamentStatus(status);
      });
    }

    // Cleanup the event listener on unmount
    return () => {
      if (socket) {
        socket.off("matchNotification");
        socket.off("updateMatch");
        socket.off("updateStatus");
      }
    };
  }, [socket, toast, page, perPage]);

  // Check-in for tournament
  const handleCheckIn = async () => {
    try {
      await checkInTournament(player._id);
      setPlayer({ ...player, checkedIn: true });
    } catch (error) {
      console.error("Error during check-in:", error);
    }
  };

  const handleUpdateProfile = async () => {
    await updateProfile({ id: player._id, name, role, skillLevel });
    setIsUpdateModalOpen(false);
  };

  const changePerPage = async (e) => {
    setPerPage(Number(e.target.value));
  };

  // Pickleball game rule validation
  const validateScore = () => {
    const team1 = parseInt(team1Score);
    const team2 = parseInt(team2Score);

    if (isNaN(team1) || isNaN(team2)) {
      toast({
        title: "Error",
        description: "Both scores must be numbers.",
        variant: "destructive",
      });
      return false;
    }

    if (team1 >= 0 && team2 >= 0) {
      return true;
    }

    toast({
      title: "Error",
      description:
        "The winning team must have at least 11 points and win by at least 2 points.",
      variant: "destructive",
    });
    return false;
  };

  // Handle match score submission
  const handleSubmitScore = async () => {
    if (selectedMatch && team1Score && team2Score) {
      if (!validateScore()) return; // Check if scores are valid

      try {
        const scores = [
          ...selectedMatch.team1.map((p) => {
            return { player: p._id, score: parseInt(team1Score) };
          }),
          ...selectedMatch.team2.map((p) => {
            return { player: p._id, score: parseInt(team2Score) };
          }),
        ];

        await submitMatchScore(selectedMatch._id, player._id, scores);
        const leaderboardData = await fetchLeaderboard(page, perPage);
        setLeaderboard([...leaderboardData.players]);
        const playerId = localStorage.getItem("id");
        if (playerId !== "") {
          const currentMatchesData = await getActiveMatches(playerId);
          setCurrentMatches([...currentMatchesData]);
        }
        setIsModalOpen(false);
        setIsConfirmationModalOpen(false); // Close confirmation modal
        setTeam1Score("");
        setTeam2Score("");
        toast({
          title: "Success",
          description: "Score submitted successfully!",
        });
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          variant: "destructive",
        });
      }
    } else {
      toast({
        title: "Error",
        description: "Please enter scores for both teams.",
        variant: "destructive",
      });
    }
  };

  const cancelMatch = async (match) => {
    try {
      if (
        window.confirm(
          "Are you sure you want to cancel this match? The other players won't be able to play either."
        )
      ) {
        await cancelMatchPlayer(match._id);
        const playerId = localStorage.getItem("id");
        if (playerId !== "") {
          const currentMatchesData = await getActiveMatches(playerId);
          setCurrentMatches([...currentMatchesData]);
        }
      }
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    }
  };
  // Open modal for score submission
  const openSubmitScoreModal = (match) => {
    setSelectedMatch(match);
    setIsModalOpen(true);
  };

  // Open confirmation modal before submitting score
  const openConfirmationModal = (e) => {
    e.preventDefault();
    setIsConfirmationModalOpen(true);
  };

  const handleLeave = async () => {
    try {
      if (window.confirm("Are you sure to leave the tournament?")) {
        await leaveTournament(player._id);
        setPlayer({ ...player, checkedIn: false });
      }
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <div className="mb-2">
        <Card>
          {tournamentStatus === "paused" && (
            <CardHeader>Tournament is paused.</CardHeader>
          )}
          {tournamentStatus === "created" && (
            <CardHeader>Stay tuned! Game's starting soon</CardHeader>
          )}
          {tournamentStatus === "in_progress" && (
            <CardHeader>Tournament is active!</CardHeader>
          )}
        </Card>
      </div>
      <div className="space-y-6">
        <Card>
          <CardHeader>
            <CardTitle className="text-xl sm:text-4xl font-bold">
              Welcome, {player?.name}!
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between w-full">
              <div>
                <p>
                  Status:{" "}
                  {player?.checkedIn ? (
                    <span className="text-[#00FF00]">Checked-in</span>
                  ) : (
                    <span className="text-destructive">Unenrolled </span>
                  )}
                </p>
              </div>
              <div>
                {player?.checkedIn ? (
                  <Button onClick={handleLeave} className="mt-4">
                    Leave Tournament
                  </Button>
                ) : (
                  <Button onClick={handleCheckIn} className="mt-4">
                    Check-In for Tournament
                  </Button>
                )}
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Current Matches Section */}
        <Card>
          <CardHeader>
            <CardTitle>Upcoming & Current Matches</CardTitle>
          </CardHeader>
          <CardContent>
            {currentMatches.filter((p) => p.status !== "completed").length >
            0 ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Match</TableHead>
                    <TableHead>Team 1</TableHead>
                    <TableHead>Team 2</TableHead>
                    <TableHead>Court</TableHead>
                    <TableHead>Start Time</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {currentMatches
                    .filter((p) => p.status !== "completed")
                    .map((match, index) => (
                      <TableRow key={match._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <p>
                            {match?.team1[0]?.name} (
                            {capitalizeFirstLetter(match?.team1[0]?.skillLevel)}
                            )
                          </p>{" "}
                          <p>
                            {match?.team1[1]?.name} (
                            {capitalizeFirstLetter(match?.team1[1]?.skillLevel)}
                            )
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>
                            {match?.team2[0]?.name} (
                            {capitalizeFirstLetter(match?.team2[0]?.skillLevel)}
                            )
                          </p>{" "}
                          <p>
                            {match?.team2[1]?.name} (
                            {capitalizeFirstLetter(match?.team2[1]?.skillLevel)}
                            )
                          </p>
                        </TableCell>
                        <TableCell>{match.courtNumber}</TableCell>
                        <TableCell>
                          {new Date(match.startTime).toLocaleTimeString(
                            "en-US",
                            { hour: "2-digit", minute: "2-digit" }
                          )}
                        </TableCell>
                        <TableCell>
                          {capitalizeFirstLetter(match.status)}
                        </TableCell>
                        <TableCell>
                          {match.status === "pending" && (
                            <Button onClick={() => cancelMatch(match)}>
                              Cancel
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <p className="text-xl">{`Hang out this round. Grab a taco and a drink. Open bar until 5p : )`}</p>
            )}
          </CardContent>
        </Card>
        {/* Current Matches Section */}
        <Card>
          <CardHeader>
            <CardTitle>History</CardTitle>
            <p>Please submit the scores right after the match is finished.</p>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Match</TableHead>
                  <TableHead>Team 1</TableHead>
                  <TableHead>Team 2</TableHead>
                  <TableHead>Score</TableHead>
                  <TableHead>Court</TableHead>
                  <TableHead>Start Time</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {currentMatches.length > 0 ? (
                  currentMatches
                    .filter((p) => p.status === "completed")
                    .map((match, index) => (
                      <TableRow key={match._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <p>
                            {match?.team1[0]?.name} (
                            {capitalizeFirstLetter(match?.team1[0]?.skillLevel)}
                            )
                          </p>{" "}
                          <p>
                            {match?.team1[1]?.name} (
                            {capitalizeFirstLetter(match?.team1[1]?.skillLevel)}
                            )
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>
                            {match?.team2[0]?.name} (
                            {capitalizeFirstLetter(match?.team2[0]?.skillLevel)}
                            )
                          </p>{" "}
                          <p>
                            {match?.team2[1]?.name} (
                            {capitalizeFirstLetter(match?.team2[1]?.skillLevel)}
                            )
                          </p>
                        </TableCell>
                        <TableCell>
                          {
                            match.scores.find((entry) =>
                              match.team1
                                .map((p) => p._id)
                                .includes(entry.player)
                            )?.score
                          }{" "}
                          -{" "}
                          {
                            match.scores.find((entry) =>
                              match.team2
                                .map((p) => p._id)
                                .includes(entry.player)
                            )?.score
                          }
                        </TableCell>
                        <TableCell>{match.courtNumber}</TableCell>
                        <TableCell>
                          {new Date(match.startTime).toLocaleTimeString(
                            "en-US",
                            { hour: "2-digit", minute: "2-digit" }
                          )}
                        </TableCell>
                        <TableCell>
                          {capitalizeFirstLetter(match.status)}
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => openSubmitScoreModal(match)}>
                            Submit Score
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>No History.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        {/* Leaderboard Section */}
        <Card>
          <CardHeader>
            <CardTitle>Leaderboard</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex justify-end mb-4">
              <label className="mr-2">Results per page:</label>
              <select
                value={perPage}
                onChange={changePerPage}
                className="border border-gray-300 rounded-md p-2"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Rank</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Level</TableHead>
                  <TableHead>Total Matches</TableHead>
                  <TableHead>Wins</TableHead>
                  <TableHead>Losses</TableHead>
                  <TableHead>Total Score</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {leaderboard.length > 0 ? (
                  leaderboard.map((player, index) => (
                    <TableRow key={player._id}>
                      <TableCell>{(page - 1) * perPage + index + 1}</TableCell>
                      <TableCell>{player.name}</TableCell>
                      <TableCell>
                        {capitalizeFirstLetter(player.skillLevel)}
                      </TableCell>
                      <TableCell>{player.matchesPlayed}</TableCell>
                      <TableCell>{player.wins}</TableCell>
                      <TableCell>{player.losses}</TableCell>
                      <TableCell>{player.totalPoints}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>No players available.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* Pagination controls */}
            <div className="flex justify-center mt-4">
              <button
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
                className={`px-4 py-2 border ${
                  page === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-primary text-white"
                }`}
              >
                Previous
              </button>
              <span className="px-4 py-2">{page}</span>
              <button
                onClick={() => setPage(page + 1)}
                disabled={leaderboard.length < perPage}
                className={`px-4 py-2 border ${
                  leaderboard.length < perPage
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-primary text-white"
                }`}
              >
                Next
              </button>
            </div>
          </CardContent>
        </Card>

        {/* Submit Score Modal */}
        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
            <div className="bg-white p-6 rounded-md shadow-lg w-full max-w-lg relative">
              <h2 className="text-2xl font-bold mb-4 text-center">
                Score Submission
              </h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              >
                &#10005; {/* Close (X) symbol */}
              </button>
              <form className="space-y-4">
                <div>
                  <label className="block text-sm font-medium">
                    Team 1 Score
                  </label>
                  <input
                    type="number"
                    value={team1Score}
                    onChange={(e) => setTeam1Score(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter score for Team 1"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium">
                    Team 2 Score
                  </label>
                  <input
                    type="number"
                    value={team2Score}
                    onChange={(e) => setTeam2Score(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter score for Team 2"
                  />
                </div>
                <Button
                  onClick={openConfirmationModal}
                  className="w-full py-3 text-lg bg-primary text-white"
                >
                  Submit Score
                </Button>
              </form>
            </div>
          </div>
        </Dialog>

        {/* Update Profile Modal */}
        <Dialog
          open={isUpdateModalOpen}
          onClose={() => setIsUpdateModalOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
            <div className="bg-white p-6 rounded-md shadow-lg w-full max-w-lg relative">
              <h2 className="text-2xl font-bold mb-4 text-center">Profile</h2>
              <button
                onClick={() => setIsUpdateModalOpen(false)}
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              >
                &#10005; {/* Close (X) symbol */}
              </button>
              <form className="space-y-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Level
                  </label>
                  <select
                    value={skillLevel}
                    onChange={(e) => setSkillLevel(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2"
                  >
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="expert">Expert</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Role
                  </label>
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2"
                  >
                    <option value="investor">Investor</option>
                    <option value="operator">Operator</option>
                    <option value="engineer">Engineer</option>
                    <option value="founder">Founder</option>
                  </select>
                </div>
                <Button
                  onClick={handleUpdateProfile}
                  className="w-full py-3 text-lg bg-primary text-white"
                >
                  Update
                </Button>
              </form>
            </div>
          </div>
        </Dialog>

        {/* Confirmation Modal */}
        <Dialog
          open={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
            <div className="bg-white p-6 rounded-md shadow-lg w-full max-w-lg relative">
              <h2 className="text-xl font-bold mb-4 text-center">
                Confirm Score Submission
              </h2>
              <button
                onClick={() => setIsConfirmationModalOpen(false)}
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              >
                &#10005; {/* Close (X) symbol */}
              </button>
              <p>Are you sure you want to submit the score?</p>
              <div className="mt-4 space-x-4 flex justify-center">
                <Button
                  onClick={handleSubmitScore}
                  className="py-2 px-4 bg-green-500 text-white"
                >
                  Yes, Submit
                </Button>
                <Button
                  onClick={() => setIsConfirmationModalOpen(false)}
                  className="py-2 px-4 bg-gray-300"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default PlayerDashboard;
