import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Input } from "./ui/input"; // Importing Input for player info form
import { Dialog } from "@headlessui/react"; // Importing Dialog for modal functionality
import { setAuthToken } from "../utils/auth";
import { checkPlayerByName, registerPlayer } from "../services/service.js";
import { useToast } from "./hooks/use-toast";
const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [skillLevel, setSkillLevel] = useState("beginner");
  const [role, setRole] = useState("investor");
  const navigate = useNavigate();
  const { toast } = useToast();

  // Automatically open the modal when the page loads
  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  // In the Join Tournament Modal
  const handleJoinTournament = async (e) => {
    try {
      e.preventDefault();
      // Check if player already exists
      const { token } = await checkPlayerByName(name);

      if (token) {
        if (
          window.confirm(
            "Looks like a user with this name already exists. Are you sure you've registered before?"
          )
        ) {
          // Player exists, go to player dashboard
          setAuthToken(token);
          navigate(`/dashboard`);
        } else {
          alert("Please choose different name.");
        }
      }
    } catch (error) {
      try {
        const { token } = await registerPlayer({ name, skillLevel, role });
        setAuthToken(token);
        navigate(`/dashboard`);
      } catch (e) {
        toast({
          title: "Error",
          description: e.message,
          variant: "destructive",
        });
      }
    }
  };

  return (
    <div className="bg-white text-gray-800 min-h-screen flex flex-col justify-center items-center">
      <div className="text-center space-y-6">
        <h1 className="text-4xl font-bold mb-6">Welcome to the Tournament</h1>

        <div className="flex justify-center space-x-4">
          {/* Join Tournament Button */}
          <Button
            onClick={() => setIsModalOpen(true)}
            className="px-6 py-3 text-lg bg-primary text-white hover:bg-primary-dark transition duration-300"
          >
            Join Tournament
          </Button>
        </div>
      </div>

      {/* Join Tournament Modal */}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-lg w-full max-w-lg relative">
            {/* Close button at the top-right corner */}
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              &#10005; {/* Close (X) symbol */}
            </button>

            <h2 className="text-2xl font-bold mb-4 text-center">
              Join the Tournament
            </h2>

            <form className="space-y-4">
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <Input
                type="text"
                placeholder="Enter Your Name"
                className="w-full p-3 border border-gray-300 rounded-md"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Skill Level
                </label>
                <select
                  value={skillLevel}
                  onChange={(e) => setSkillLevel(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="expert">Expert</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Role
                </label>
                <select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="investor">Investor</option>
                  <option value="operator">Operator</option>
                  <option value="engineer">Engineer</option>
                  <option value="founder">Founder</option>
                </select>
              </div>

              {/* Join Tournament Button */}
              <Button
                className="w-full py-3 mt-3 text-lg bg-primary text-white hover:bg-primary-dark transition duration-300"
                onClick={handleJoinTournament}
              >
                Join Tournament
              </Button>
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default LandingPage;
