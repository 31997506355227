import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SocketProvider } from "./contexts/SocketContext";
import AdminDashboard from "./components/AdminDashboard";
import PlayerDashboard from "./components/PlayerDashboard";
import Login from "./components/Login";
// import Register from './components/Register';
import LandingPage from "./components/LandingPage";
import { Toaster } from "./components/ui/toaster";
// import {jwtDecode} from 'jwt-decode'; // Import jwt-decode
// import { useNavigate } from 'react-router-dom';
import AppHeader from "./components/AppHeader";
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isSticky, setIsSticky] = useState(false); // Track if header is sticky
  // const navigate = useNavigate();
  // Handle login: Save token and role
  const handleLogin = (role, token) => {
    setIsAuthenticated(true);
    localStorage.setItem("token", token); // Store token in localStorage
  };

  // Handle logout: Clear token and state
  const handleLogout = () => {
    setIsAuthenticated(false);
    // setUserRole(null);
    // setPlayer(null);
    localStorage.removeItem("token"); // Remove token from localStorage
    window.location.href = "/";
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        // const decodedToken = jwtDecode(token); // Decode the token to get role and other info
        // console.log(decodedToken)
        // setUserRole(decodedToken.role);
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token"); // Clear invalid token
      }
    }
  }, []);

  // Add scroll event listener to toggle sticky class based on scroll position
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.scrollY || document.documentElement.scrollTop;
  //     const clientHeight = document.documentElement.clientHeight;
  //     const scrollHeight = document.documentElement.scrollHeight;

  //     if (scrollTop + clientHeight >= scrollHeight - 5) {
  //       setIsSticky(true);
  //     } else {
  //       setIsSticky(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll); // Cleanup on component unmount
  //   };
  // }, []);

  return (
    <SocketProvider>
      <Router>
        <div className="min-h-screen bg-background text-foreground">
          {/* Updated header to be sticky */}
          <AppHeader
            isAuthenticated={isAuthenticated}
            onLogout={handleLogout}
          />
          <main className="container mx-auto p-4">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route
                path="/admin"
                element={<AdminDashboard onLogout={handleLogout} />}
              />
              <Route
                path="/login"
                element={
                  <Login onLogin={(role, token) => handleLogin(role, token)} />
                }
              />
              {/* <Route
                path="/register"
                element={<Register/>}
              /> */}
              <Route path="/dashboard" element={<PlayerDashboard />} />
            </Routes>
          </main>
          <Toaster />
        </div>
      </Router>
    </SocketProvider>
  );
};

export default App;
