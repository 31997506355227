import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { login } from "../services/service"; // ES6 import for login API service
import { setAuthToken } from "../utils/auth"; // Helper to manage JWT tokens
import { Eye, EyeOff } from "lucide-react"; // Icons for show/hide password

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!username || !password) {
      setErrorMessage("Both Username and Password are required.");
      return;
    }

    setErrorMessage("");
    setLoading(true);

    try {
      const { token, role } = await login(username, password); // Await async login call
      setAuthToken(token); // Store the JWT token in localStorage or cookies
      onLogin(role, token);
      navigate(role === "admin" ? "/admin" : "/dashboard"); // Navigate based on role
    } catch (error) {
      setErrorMessage("Invalid login credentials. Please try again.");
    } finally {
      setLoading(false); // Ensure loading state is removed
    }
  };

  // Redirect to Register page
  // const handleRegisterRedirect = () => {
  //   navigate('/register');
  // };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-6 text-center">Login</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Username
          </label>
          <Input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username"
            className="w-full"
          />
        </div>

        <div className="mb-4 relative">
          <label className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            className="w-full pr-10"
          />
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={togglePasswordVisibility}
            style={{ top: "50%", transform: "translateY(-20%)" }}
          >
            {showPassword ? (
              <EyeOff className="h-5 w-5 text-gray-500" />
            ) : (
              <Eye className="h-5 w-5 text-gray-500" />
            )}
          </div>
        </div>

        {errorMessage && (
          <p className="text-red-600 mb-4 text-center">{errorMessage}</p>
        )}

        <div className="text-right mb-4">
          <span
            className="text-sm text-blue-600 hover:text-blue-800 cursor-pointer"
            onClick={() => alert("Redirect to forgot password")}
          >
            Forgot Password?
          </span>
        </div>

        <Button
          onClick={handleLogin}
          className="w-full mb-4"
          disabled={loading}
        >
          {loading ? "Logging in..." : "Login"}
        </Button>

        {/* <Button onClick={handleRegisterRedirect} className="w-full" variant="outline">
          Register
        </Button> */}
      </div>
    </div>
  );
};

export default Login;
