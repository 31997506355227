import React, { useState, useEffect } from "react";
import { Button } from "./ui/button";
import { Card, CardHeader, CardContent, CardTitle } from "./ui/card";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import {
  fetchTournamentStats,
  startTournament,
  pauseTournament,
  endTournament,
  resumeTournament,
  // removeMatch,
  updateMatchScore,
} from "../services/service";
import { useToast } from "./hooks/use-toast";
import { useSocket } from "../contexts/SocketContext";
import { Input } from "./ui/input"; // For input fields in score update
const CollapsibleCard = ({ title, children, defaultStatus = false }) => {
  const [isOpen, setIsOpen] = useState(defaultStatus); // Set to true for initially expanded state

  // Toggle the card content on click
  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card>
      <CardHeader onClick={toggleCard} style={{ cursor: "pointer" }}>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      {isOpen && <CardContent>{children}</CardContent>}
    </Card>
  );
};

const Statistics = () => {
  const socket = useSocket();
  const [editingMatch, setEditingMatch] = useState(null); // Track match being edited
  const [updatedScore, setUpdatedScore] = useState({}); // Track updated score
  const [time, setTime] = useState(0); // Initial time state
  const [isActive, setIsActive] = useState(false); // Timer active state
  const [status, setStatus] = useState("");
  const [stats, setStats] = useState({
    totalPlayers: 0,
    topPlayers: [],
    matchesPlayed: 0,
    playersNotPlayed: 0,
    playersLeft: 0,
    gameHistogram: [0, 0, 0, 0, 0],
    tournamentStatus: "",
  });
  const [gameHistogramData, setGameHistogramData] = useState([
    { games: "1 game", count: 2 },
    { games: "2 games", count: 4 },
    { games: "3 games", count: 3 },
    { games: "4 games", count: 5 },
    { games: "5+ games", count: 1 },
  ]);
  const { toast } = useToast();
  useEffect(() => {
    const loadStats = async () => {
      const tournamentStats = await fetchTournamentStats(); // Fetch stats from the API
      setStats({ ...tournamentStats.stats });
      const histogramData = [
        { games: "1 game", count: tournamentStats.stats.gameHistogram[0] },
        { games: "2 games", count: tournamentStats.stats.gameHistogram[1] },
        { games: "3 games", count: tournamentStats.stats.gameHistogram[2] },
        { games: "4 games", count: tournamentStats.stats.gameHistogram[3] },
        { games: "5+ games", count: tournamentStats.stats.gameHistogram[4] },
      ];

      setGameHistogramData([...histogramData]);
    };
    loadStats();
    if (socket) {
      socket.emit("registerAdminSocket");
    }
  }, [socket]);

  useEffect(() => {
    let interval = null;
    if (isActive && time > 0) {
      // Start the timer and decrease time by 1 every second
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (!isActive && time !== 0) {
      clearInterval(interval);
    }

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [isActive, time]);

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  useEffect(() => {
    // Listen for match notifications
    if (socket) {
      socket.on("updateDashboard", async (data) => {
        const tournamentStats = await fetchTournamentStats(); // Fetch stats from the API
        setStats({ ...tournamentStats.stats });
        const histogramData = [
          { games: "1 game", count: tournamentStats.stats.gameHistogram[0] },
          { games: "2 games", count: tournamentStats.stats.gameHistogram[1] },
          { games: "3 games", count: tournamentStats.stats.gameHistogram[2] },
          { games: "4 games", count: tournamentStats.stats.gameHistogram[3] },
          { games: "5+ games", count: tournamentStats.stats.gameHistogram[4] },
        ];

        setGameHistogramData([...histogramData]);

        if (data.status !== "") {
          setTime(data.duration * 60);
          setIsActive(true);
          setStatus(data.status);
        }
      });
    }

    // Cleanup the event listener on unmount
    return () => {
      if (socket) {
        socket.off("updateDashboard");
      }
    };
  }, [socket]);

  const handleStartTournament = async () => {
    try {
      await startTournament(); // Start tournament with selected values
      setStats({ ...stats, tournamentStatus: "in_progress" });
      toast({
        title: "Success",
        description: "Tournament Started",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    }
  };

  const handlePauseTournament = async () => {
    try {
      await pauseTournament(); // Start tournament with selected values
      setStats({ ...stats, tournamentStatus: "paused" });
      toast({
        title: "Success",
        description: "Tournament Paused",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    }
  };

  const handleEndTournament = async () => {
    try {
      await endTournament(); // Start tournament with selected values
      setStats({ ...stats, tournamentStatus: "ended" });
      toast({
        title: "Success",
        description: "Tournament Ended",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    }
  };

  const handleResumeTournament = async () => {
    try {
      await resumeTournament(); // Start tournament with selected values
      setStats({ ...stats, tournamentStatus: "in_progress" });
      toast({
        title: "Success",
        description: "Tournament Resumed",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    }
  };

  // Save updated score
  const handleUpdateScore = async (matchId) => {
    try {
      await updateMatchScore(matchId, updatedScore);
      setEditingMatch(null); // Exit editing mode
      const tournamentStats = await fetchTournamentStats(); // Fetch stats from the API
      setStats({ ...tournamentStats.stats });
      const histogramData = [
        { games: "1 game", count: tournamentStats.stats.gameHistogram[0] },
        { games: "2 games", count: tournamentStats.stats.gameHistogram[1] },
        { games: "3 games", count: tournamentStats.stats.gameHistogram[2] },
        { games: "4 games", count: tournamentStats.stats.gameHistogram[3] },
        { games: "5+ games", count: tournamentStats.stats.gameHistogram[4] },
      ];

      setGameHistogramData([...histogramData]);
    } catch (error) {
      console.error("Error updating match score:", error);
    }
  };

  // Toggle score editing mode
  const startEditMatch = (match) => {
    setEditingMatch(match._id);

    setUpdatedScore({
      team1Score:
        match.scores.find((entry) =>
          match.team1.map((p) => p._id).includes(entry.player)
        )?.score || 0,
      team2Score:
        match.scores.find((entry) =>
          match.team2.map((p) => p._id).includes(entry.player)
        )?.score || 0,
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-2 space-y-2">
        <Card>
          <CardHeader>
            {stats.tournamentStatus === "in_progress" ? (
              <div className="">
                <p className="text-bold text-xl">Tournament is Active!</p>
              </div>
            ) : stats.tournamentStatus === "pause" ? (
              <p className="text text-destructive">Tournament is Paused! </p>
            ) : (
              <p className="text text-destructive">No tournament available. </p>
            )}
          </CardHeader>
        </Card>
        {stats.tournamentStatus === "in_progress" && (
          <Card>
            <CardHeader>
              <div className="flex w-full justify-between">
                {status === "pending" ? (
                  <p>Match Break Time</p>
                ) : status === "active" ? (
                  <p>Match is in Progress</p>
                ) : (
                  <p>Score Submission Time</p>
                )}
                <p>{formatTime(time)}</p>
              </div>
            </CardHeader>
          </Card>
        )}
      </div>
      <div className="grid grid-cols-8 gap-6">
        <div className="grid col-span-6">
          <div className="grid grid-cols-1 gap-6">
            {/* Statistics Section */}

            <CollapsibleCard title={`Top Players`} defaultStatus={true}>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>No</TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Points</TableHead>
                    <TableHead>Wins</TableHead>
                    <TableHead>Losses</TableHead>
                    <TableHead>Total Matches</TableHead>
                    <TableHead>Level</TableHead>
                    <TableHead>Role</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {stats?.topPlayers?.length > 0 ? (
                    stats.topPlayers.map((player, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{player.name}</TableCell>
                        <TableCell>{player.totalPoints}</TableCell>
                        <TableCell>{player.wins}</TableCell>
                        <TableCell>{player.losses}</TableCell>
                        <TableCell>{player.matchesPlayed}</TableCell>
                        <TableCell>{player.skillLevel}</TableCell>
                        <TableCell>{player.category}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>No players available</TableCell>
                    </TableRow>
                  )}
                  <TableCell></TableCell>
                </TableBody>
              </Table>
            </CollapsibleCard>

            <CollapsibleCard title={`Total Matches: ${stats.matchesPlayed}`}>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Match</TableHead>
                    <TableHead>Team 1</TableHead>
                    <TableHead>Team 2</TableHead>
                    <TableHead>Score</TableHead>
                    <TableHead>Court</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>StartedTime</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {stats.matches?.length > 0 ? (
                    stats.matches.map((match, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <p>
                            {match?.team1[0]?.name} (
                            {match?.team1[0]?.skillLevel})
                          </p>{" "}
                          <p>
                            {match?.team1[1]?.name} (
                            {match?.team1[1]?.skillLevel})
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>
                            {match?.team2[0]?.name} (
                            {match?.team2[0]?.skillLevel})
                          </p>{" "}
                          <p>
                            {match?.team2[1]?.name} (
                            {match?.team2[1]?.skillLevel})
                          </p>
                        </TableCell>
                        <TableCell>
                          {editingMatch === match._id ? (
                            <div className="flex gap-3">
                              <Input
                                type="number"
                                value={updatedScore.team1Score}
                                onChange={(e) =>
                                  setUpdatedScore({
                                    ...updatedScore,
                                    team1Score: Number(e.target.value),
                                  })
                                }
                                className="w-20 mr-2"
                              />
                              <Input
                                type="number"
                                value={updatedScore.team2Score}
                                onChange={(e) =>
                                  setUpdatedScore({
                                    ...updatedScore,
                                    team2Score: Number(e.target.value),
                                  })
                                }
                                className="w-20"
                              />
                            </div>
                          ) : (
                            <>
                              {
                                match.scores.find((entry) =>
                                  match.team1
                                    .map((p) => p._id)
                                    .includes(entry.player)
                                )?.score
                              }{" "}
                              -{" "}
                              {
                                match.scores.find((entry) =>
                                  match.team2
                                    .map((p) => p._id)
                                    .includes(entry.player)
                                )?.score
                              }
                            </>
                          )}
                        </TableCell>
                        <TableCell>{match.courtNumber}</TableCell>
                        <TableCell>{match.status}</TableCell>
                        <TableCell>
                          {new Date(match.startTime).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {editingMatch === match._id ? (
                            <>
                              <Button
                                onClick={() => handleUpdateScore(match._id)}
                                className="mr-2"
                              >
                                Save
                              </Button>
                              <Button
                                onClick={() => setEditingMatch(null)}
                                variant="outline"
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <Button onClick={() => startEditMatch(match)}>
                              Update Score
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>No matches available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CollapsibleCard>

            <CollapsibleCard
              title={`Players Enrolled: ${stats.playersEnrolled?.length}`}
            >
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>No</TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Points</TableHead>
                    <TableHead>Wins</TableHead>
                    <TableHead>Losses</TableHead>
                    <TableHead>Total Matches</TableHead>
                    <TableHead>Level</TableHead>
                    <TableHead>Role</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {stats?.playersEnrolled?.length > 0 ? (
                    stats.playersEnrolled.map((player, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{player.name}</TableCell>
                        <TableCell>{player.totalPoints}</TableCell>
                        <TableCell>{player.wins}</TableCell>
                        <TableCell>{player.losses}</TableCell>
                        <TableCell>{player.matchesPlayed}</TableCell>
                        <TableCell>{player.skillLevel}</TableCell>
                        <TableCell>{player.category}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>No players available</TableCell>
                    </TableRow>
                  )}
                  <TableCell></TableCell>
                </TableBody>
              </Table>
            </CollapsibleCard>

            <CollapsibleCard
              title={`Players Unplayed : ${stats.playersNotPlayed?.length}`}
            >
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>No</TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Points</TableHead>
                    <TableHead>Wins</TableHead>
                    <TableHead>Losses</TableHead>
                    <TableHead>Total Matches</TableHead>
                    <TableHead>Level</TableHead>
                    <TableHead>Role</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {stats?.playersNotPlayed?.length > 0 ? (
                    stats.playersNotPlayed.map((player, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{player.name}</TableCell>
                        <TableCell>{player.totalPoints}</TableCell>
                        <TableCell>{player.wins}</TableCell>
                        <TableCell>{player.losses}</TableCell>
                        <TableCell>{player.matchesPlayed}</TableCell>
                        <TableCell>{player.skillLevel}</TableCell>
                        <TableCell>{player.category}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>No players available</TableCell>
                    </TableRow>
                  )}
                  <TableCell></TableCell>
                </TableBody>
              </Table>
            </CollapsibleCard>

            <CollapsibleCard
              title={`Players Left : ${stats.playersLeft?.length}`}
            >
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>No</TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Points</TableHead>
                    <TableHead>Wins</TableHead>
                    <TableHead>Losses</TableHead>
                    <TableHead>Total Matches</TableHead>
                    <TableHead>Level</TableHead>
                    <TableHead>Role</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {stats?.playersLeft?.length > 0 ? (
                    stats.playersLeft.map((player, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{player.name}</TableCell>
                        <TableCell>{player.totalPoints}</TableCell>
                        <TableCell>{player.wins}</TableCell>
                        <TableCell>{player.losses}</TableCell>
                        <TableCell>{player.matchesPlayed}</TableCell>
                        <TableCell>{player.skillLevel}</TableCell>
                        <TableCell>{player.category}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>No players available</TableCell>
                    </TableRow>
                  )}
                  <TableCell></TableCell>
                </TableBody>
              </Table>
            </CollapsibleCard>

            <CollapsibleCard title={`Match Stats`} defaultStatus={true}>
              <p>Histogram of the number of games players have played:</p>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={gameHistogramData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="games" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CollapsibleCard>
          </div>
        </div>
        <div className="grid col-span-2">
          {/* Control Buttons Section */}
          <Card>
            <CardHeader></CardHeader>
            <CardContent>
              <div className="flex flex-col space-y-4">
                {(stats.tournamentStatus === "ended" ||
                  stats.tournamentStatus === "created") && (
                  <Button onClick={handleStartTournament}>
                    Start Tournament
                  </Button>
                )}
                {stats.tournamentStatus === "in_progress" && (
                  <Button onClick={handlePauseTournament}>Pause</Button>
                )}
                {stats.tournamentStatus === "in_progress" && (
                  <Button onClick={handleEndTournament}>End Tournament</Button>
                )}
                {stats.tournamentStatus === "paused" && (
                  <Button onClick={handleResumeTournament}>Resume</Button>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
