import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { Button } from './ui/button';

const AppHeader = ({ isAuthenticated, onLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleLogout = () => {
    onLogout();
    setIsDropdownOpen(false); // Close dropdown on logout
  };

  return (
    <header className={`bg-primary text-primary-foreground p-4`}>
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <Link to="/">
          <h1 className="text-3xl font-bold">Kiva AI Pickleball Club</h1>
        </Link>

        {/* Conditionally render Login or User icon */}
        {isAuthenticated ? (
          <div className="relative">
            {/* User Icon (Circle with user inside) */}
            <div
              className="cursor-pointer rounded-full w-10 h-10 bg-gray-200 flex items-center justify-center"
              onClick={toggleDropdown}
            >
              <span className="text-xl font-semibold text-primary">👤</span>{" "}
              {/* Simple User Icon */}
            </div>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                <ul className="py-2">
                  <li>
                    <button
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <Link to="/login">
            {/* <Button className="px-6 py-3 text-lg border-2 border-white text-white hover:bg-white hover:text-primary transition duration-300">
              Login
            </Button> */}
          </Link>
        )}
      </div>
    </header>
  );
};

export default AppHeader;
