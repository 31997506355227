import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Corrected import
import Statistics from "./Statistics";

const AdminDashboard = () => {
  const navigate = useNavigate();

  // Check if user is logged in on app load
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role !== "admin") {
          navigate("/login");
        }
      } catch (error) {
        localStorage.removeItem("token"); // Clear invalid token
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="flex h-screen">
      {/* Main Content Area */}
      <main className="flex-grow p-6">
        <h2 className="text-4xl">Admin Dashboard</h2>
        <Statistics></Statistics>
      </main>
    </div>
  );
};

export default AdminDashboard;
