import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

// Create a SocketContext
const SocketContext = createContext();

// Custom hook to use the SocketContext in components
export const useSocket = () => {
  return useContext(SocketContext);
};

// SocketProvider component to provide the socket instance to all children components
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize the socket connection when the component mounts
    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
      path: "/socket/",
      reconnection: true,
    });

    setSocket(newSocket);

    // Clean up the socket connection when the component unmounts
    return () => {
      newSocket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
