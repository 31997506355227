// utils/auth.js
import axios from "axios";
export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem("token", token); // Store token in localStorage
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`; // Attach token to Axios requests
  } else {
    localStorage.removeItem("token"); // Remove token from localStorage
    delete axios.defaults.headers.common["Authorization"]; // Remove token from Axios headers
  }
};

// Retrieve token from localStorage
export const getAuthToken = () => localStorage.getItem("token");
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://playwithkiva.xyz/api";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Use your backend API URL
  timeout: 10000, // Set a timeout for requests
});

// Add a request interceptor to attach the token
axiosInstance.interceptors.request.use(
  (config) => {
    // Get token from localStorage or sessionStorage
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    // If a token exists, attach it to the Authorization header
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration and errors
axiosInstance.interceptors.response.use(
  (response) => {
    // If the request is successful, simply return the response
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;

    // If the token is invalid or expired, log out the user
    if (status === 401 || status === 403) {
      // Remove token from localStorage/sessionStorage
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");

      // Redirect user to the login page
      window.location.href = "/";
    }
    console.log(error);

    return Promise.reject(error);
  }
);

export default axiosInstance;
