// src/services/service.js
import axios from "../utils/auth";

export const login = async (username, password) => {
  try {
    const response = await axios.post(`/auth/login`, { username, password });
    return response.data; // Handle success (token, user data, etc.)
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

export const registerPlayer = async (playerData) => {
  try {
    const response = await axios.post(`/auth/register`, playerData);
    return response.data; // Handle success (token, user data, etc.)
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Fetch players from the Player collection (up to 150)
export const fetchPlayers = async () => {
  try {
    const response = await axios.post(`/admin/players/available`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

export const addPlayersToTournament = async (tournamentId, players) => {
  try {
    const response = await axios.post(`/admin/add-players`, {
      tournamentId: tournamentId,
      players: players,
    });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Fetch all tournaments
export const fetchTournaments = async () => {
  try {
    const response = await axios.get(`/admin/tournament`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Create a new tournament
export const createTournament = async (tournamentData) => {
  try {
    const response = await axios.post(
      `/admin/create-tournament`,
      tournamentData
    );
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Start a tournament
export const startTournament = async (tournamentId) => {
  try {
    await axios.post(`/admin/start-tournament`, { tournamentId });
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Pause a tournament
export const pauseTournament = async (tournamentId) => {
  try {
    await axios.post(`/admin/pause-tournament`, { tournamentId });
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Resume a tournament
export const resumeTournament = async (tournamentId) => {
  try {
    await axios.post(`/admin/resume-tournament`, { tournamentId });
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// End a tournament
export const endTournament = async (tournamentId) => {
  try {
    await axios.post(`/admin/end-tournament`, { tournamentId });
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// End a tournament
export const updateTournament = async (tournamentId, updatedData) => {
  try {
    await axios.post(`/admin/update-tournament`, { tournamentId, updatedData });
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// End a tournament
export const deleteTournament = async (tournamentId) => {
  try {
    await axios.post(`/admin/delete-tournament`, { tournamentId });
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Remove player
export const removePlayer = async (playerId) => {
  try {
    await axios.post(`/admin/remove-player`, { playerId });
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};
// Remove player
export const removePlayerFromTournament = async (playerId) => {
  try {
    await axios.post(`/admin/tournament/remove-player`, { playerId });
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch current matches
export const fetchCurrentMatches = async () => {
  try {
    const response = await axios.get(`/admin/matches/current`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch upcoming matches
export const fetchUpcomingMatches = async () => {
  try {
    const response = await axios.get(`/admin/matches/upcoming`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch finished matches
export const fetchFinishedMatches = async () => {
  try {
    const response = await axios.get(`/admin/matches/finished`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch waiting players
export const fetchWaitingPlayers = async () => {
  try {
    const response = await axios.get(`/admin/players/waiting`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to create a new match
export const createMatch = async (matchData) => {
  try {
    const response = await axios.post(`/admin/matche`, { matchData });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to update match status
export const updateMatchScore = async (matchId, updatedScore) => {
  try {
    const response = await axios.post(`/admin/override-match`, {
      matchId,
      updatedScore,
    });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to update match status
export const removeMatch = async (matchId) => {
  try {
    const response = await axios.post(`/admin/matches/delete`, { matchId });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to update match status
export const fetchLeaderboard = async (page, perPage) => {
  try {
    const response = await axios.post(`/players/leaderboard`, {
      page,
      perPage,
    });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to update match status
export const checkInTournament = async (playerId) => {
  try {
    const response = await axios.post(`/players/checkin`, { playerId });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to update match status
export const leaveTournament = async (playerId) => {
  try {
    const response = await axios.post(`/players/leave`, { playerId });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to update match status
export const submitMatchScore = async (matchId, playerId, scores) => {
  try {
    const response = await axios.post(`/matches/submit-scores`, {
      matchId,
      playerId,
      scores,
    });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to update match status
export const getPlayerStat = async (username) => {
  try {
    const response = await axios.post(`/players/stat`, { username: username });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to update match status
export const updateProfile = async (data) => {
  try {
    const response = await axios.post(`/players/update`, data);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch current matches
export const getActiveMatches = async (playerId) => {
  try {
    const response = await axios.get(`/matches/active/${playerId}`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch current matches
export const checkPlayerByName = async (name) => {
  try {
    const response = await axios.get(`/auth/check?name=${name}`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch current matches
export const fetchTournamentStats = async (name) => {
  try {
    const response = await axios.get(`/admin/tournament/stats`);
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch current matches
export const getFinishedMatches = async (playerId) => {
  try {
    const response = await axios.post(`/matches/finished`, { playerId });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};

// Function to fetch current matches
export const cancelMatchPlayer = async (matchId) => {
  try {
    const response = await axios.post(`/matches/cancel`, { matchId });
    return response.data;
  } catch (error) {
    // Check if the error has a response and status code
    if (
      error.response &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      const errorMessage =
        error.response.data.message || "An unexpected error occurred";
      throw new Error(errorMessage); // Re-throw with a custom error message from the server
    } else {
      throw new Error("A network error occurred. Please try again.");
    }
  }
};
